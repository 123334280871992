@import "styles/fonts";

// bootstrap var overrides
$primary: #0000ff;
$secondary: #e5e5e5;
$headings-color: black;
$dark: #525252;
$ooze: #00f720;
$body-bg: rgba(0, 0, 0, 0);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1110px,
  xl: 1200px,
  xxl: 1900px,
);

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 1000px,
//        xl: 1140px,
//        xxl: 1610px
//);

@import "~bootstrap/scss/bootstrap";
@import "styles/mixins";
@import "styles/bootstrap-overrides";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 0;

  &.dropshadow {
    /* hard drop */
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.5);
  }
}

h3 {
  font-family: "IBM Plex Mono", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  /* or 118% */
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* Black */
  color: #000000;
}

/* **************************************************************************
* RMX Party Styles
* */
@import "styles/rmx-variables.scss";
@import "styles/rmx-typography.scss";
@import "styles/rmx-global-defaults.scss";

.content-card-header {
  text-transform: uppercase;
}

.mint-dialog {
  & .description {
    font-family: "W95FA";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* or 22px */
    letter-spacing: 0.01em;

    & .details {
      font-size: 16px;
    }
  }
}

.App {
  //this makes smoke2 works; otherwise the fixed canvas renders over the content
  position: relative;
}

// *** EMOJI BUILDER
.rmx-console {
  padding: 0 16px;

  &.fade-disabled {
    opacity: 0;
    transition: opacity 2s;
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;

    & > div.builder-panel {
      opacity: 0.5;
      //background: rgba(255,255,255,0.5)
    }
  }
}

.minting-hundred-percent-container {
  height: 100vh;
  width: 100vw;
}

.minting-hundred-percent {
  background-color: #ffef00;
  padding: 2px 10px;
  color: red;
  font-family: "ChicagoFLF", sans-serif;
  font-size: 22px;
  line-height: 90%;
  z-index: 999;
}
.minting-curse-activated {
  background-color: black;
  font-family: W95FA, serif;
  color: #00f720;
  padding: 2px 5px;
  margin: 40px auto;
  font-size: 17px;
  line-height: 90%;
  z-index: 999;
}

@media (min-width: 800px) {
  .minting-hundred-percent {
    font-size: 56px;
  }
  .minting-curse-activated {
    font-size: 32px;
  }
}

.minting-hundred-percent-span {
  background-color: #00f720;
  color: blue;
  font-family: "Times New Roman", serif;
  font-weight: bold;
}

.minting-background {
  background: #000080;
  border: none;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 5;
}

.minting-flame-frame {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.minting-flames {
  position: absolute;
  z-index: 10;
  height: 160vh;
  width: 100vw;
  image-rendering: pixelated;
  object-fit: cover;
  object-position: bottom;

  animation-duration: 5s;
  animation-name: slideUp;
  animation-timing-function: linear;
  bottom: -25vh;
}

.minting-flames.complete {
  animation-duration: 3s;
  animation-name: slideUpMoar;
  animation-timing-function: linear;
  bottom: 0vh;
}

@keyframes slideUp {
  from {
    bottom: -103vh;
  }
  to {
    bottom: -25vh;
  }
}
@keyframes slideUpMoar {
  from {
    bottom: -25vh;
  }
  to {
    bottom: 0vh;
  }
}

.minting-hourglass {
  position: absolute;
  image-rendering: pixelated;
  object-fit: contain;
  height: 50vh;
  width: 50vw;
  z-index: 15;
}

.minting-hundred-percent-flames {
  animation-duration: 3s;
  animation-name: slidein;
  img {
    z-index: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
}

@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

.minting-status-msg {
  & .modal-content {
    background: none;
    border: none;
  }

  .blinking-msg {
    font-family: "W95FA";
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 120%;
    /* or 22px */
    letter-spacing: 0.01em;
    color: #00f720;
    animation: opacity 1s ease-in-out infinite;
    opacity: 1;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.emoji-canvas-container {
  max-width: 420px;
  max-height: 420px;
  background: #ffffff;
  border: none;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5), inset -1px -1px 0px #e5e5e5;
}

// *** END EMOJI BUILDER

.connect-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0;
}

.terms-condition-link {
  font-size: 12px;
}
