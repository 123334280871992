@import "../../../styles/mixins";

.rmx-console-footer {
  padding-bottom: 136px;
  @include f-dejavu-12;

  .logo-section {
    padding-top: 28px;
  }

  .logo-text {
    margin-top: 2px;
    * {
      @include f-dejavu-12;
    }
  }

  .bar {
    width: 343px;
    color: #e5e5e5;
    opacity: 100%;
  }
}
