/* debug css */
/*
.Button,
button,
.button,
.btn {
  outline: 5px dotted rgba(255, 150, 150, 0.9);
  &:before {
    color: red;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    content: "non rmx button";
  }
}
*/
/* /debug css */

.rmx-button {
  /* debug css */
  /*
  outline: none;
  &:before {
    display: none;
    content: "";
  }
*/
  /* /debug css */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 12px 24px;
  margin: 0;
  background: var(--primary-white);
  border: none;
  color: var(--primary-blue);
  text-decoration: none;
  text-transform: uppercase;

  box-shadow: var(--rmx-button-default-box-shadow);
  transition: box-shadow 0.1s;

  &:visited,
  a:visited {
    color: var(--primary-blue);
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 60%;
    background: none;
  }

  &.active:not(.disabled, :disabled),
  &:active:not(.disabled, :disabled),
  &.focus:not(.disabled, :disabled, .active, :active),
  &:focus:not(.disabled, :disabled, .active, :active),
  &.hover:not(.disabled, :disabled, .active, :active) {
    background: var(--rmx-palette-watermelon);
    box-shadow: var(--rmx-button-pressed-box-shadow);
  }
  &:hover:not(.disabled, :disabled, .active, :active) {
    background: var(--rmx-palette-watermelon);
    box-shadow: var(--rmx-button-pressed-box-shadow);
  }

  &.cta {
    font-weight: 700;
    font-size: var(--font-button-cta-size);

    height: var(--rmx-secondary-cta-button-height);
    //width: var(--rmx-secondary-cta-button-width);

    &.disabled,
    &:disabled {
      background: none;
      color: var(--rmx-palette-light-grey);
      border: none;
      box-shadow: none;
    }

    &.active:not(.disabled, :disabled),
    &:active:not(.disabled, :disabled),
    &.focus:not(.disabled, :disabled, .active, :active),
    &:focus:not(.disabled, :disabled, .active, :active),
    &.hover:not(.disabled, :disabled, .active, :active) {
      box-shadow: var(--rmx-button-deeper-pressed-box-shadow);
    }
    &:hover:not(.disabled, :disabled, .active, :active) {
      box-shadow: var(--rmx-button-deeper-pressed-box-shadow);
    }
  }
  &.primary {
    background: var(--primary-blue);
    color: var(--primary-white);

    &.active:not(.disabled, :disabled),
    &:active:not(.disabled, :disabled),
    &.focus:not(.disabled, :disabled, .active, :active),
    &:focus:not(.disabled, :disabled, .active, :active),
    &.hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-watermelon);
      color: var(--primary-blue);
    }
    &:hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-watermelon);
      color: var(--primary-blue);
    }

    &.cta {
      height: var(--rmx-primary-cta-button-height);
      width: var(--rmx-primary-cta-button-width);
    }
  }
  &.nav {
    height: var(--rmx-nav-button-height);
    width: var(--rmx-nav-button-width);
    padding: 0;

    margin: 6px;
    background-color: var(--primary-white);

    &.active:not(.disabled, :disabled),
    &:active:not(.disabled, :disabled),
    &.focus:not(.disabled, :disabled, .active, :active),
    &:focus:not(.disabled, :disabled, .active, :active),
    &.hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-flamer);
      background: var(--button-active-background-fire);
      box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
        inset -1px -1px 0px var(--box-light-edge);
    }

    &:hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-flamer);
      background: var(--button-active-background-fire);
      box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
        inset -1px -1px 0px var(--box-light-edge);
    }

    &.disabled,
    &:disabled {
      background: none;
      color: var(--rmx-palette-light-grey);
    }
  }
  &.trait-remove {
    height: var(--rmx-traitremove-button-height);
    width: var(--rmx-traitremove-button-width);
    padding: 0;

    &.focus:not(.disabled, :disabled, .active, :active),
    &:focus:not(.disabled, :disabled, .active, :active),
    &.hover:not(.disabled, :disabled, .active, :active) {
      box-shadow: var(--rmx-button-default-box-shadow);
      background: var(--rmx-palette-lighter-grey);
    }
    &:hover:not(.disabled, :disabled, .active, :active) {
      box-shadow: var(--rmx-button-default-box-shadow);
      background: var(--rmx-palette-lighter-grey);
    }
    &.active:not(.disabled, :disabled),
    &:active:not(.disabled, :disabled) {
      box-shadow: var(--rmx-button-pressed-box-shadow);
      background: var(--rmx-palette-lighter-grey);
    }

    .trash-icon {
      height: 12px;
      width: 14px;
    }

    &.disabled,
    &:disabled {
      color: var(--rmx-palette-dark-grey);
      opacity: 100%;
    }
  }

  &.trait {
    height: var(--rmx-trait-button-height);
    width: var(--rmx-trait-button-width);
    padding: 14px;

    img {
      max-width: 48px;
      max-height: 48px;
    }

    &.selected,
    &.focus:not(.disabled, :disabled, .active, :active),
    &:focus:not(.disabled, :disabled, .active, :active),
    &.hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-active);
      box-shadow: var(--rmx-button-default-box-shadow);
    }
    &:hover:not(.disabled, :disabled, .active, :active) {
      background: var(--rmx-palette-active);
      box-shadow: var(--rmx-button-default-box-shadow);
    }
    &.active:not(.disabled, :disabled),
    &:active:not(.disabled, :disabled) {
      background: var(--rmx-palette-active);
      box-shadow: var(--rmx-button-deeper-pressed-box-shadow);
    }
  }
}

.content:has(.rmx-button:only-child) {
  padding: 0 !important;
}
.content .rmx-button {
  height: 100%;
  width: 100%;
}

@media (min-width: 512px) {
  .rmx-button {
    &.trait-remove {
      &.active:not(.disabled, :disabled),
      &:active:not(.disabled, :disabled) {
        box-shadow: var(--rmx-button-deeper-pressed-box-shadow);
      }

      .trash-icon {
        height: 21px;
        width: 24px;
      }
    }

    &.trait {
      padding: 16px;
    }
  }

  .rmx-console ~ .cta-cursed-emoji {
    --rmx-primary-cta-button-width: 420px;
  }
}
