@import "src/styles/mixins";

.modal-text {
  padding-top: 10px;
  padding-bottom: 10px;
  @include f-times-18;
  box-shadow: inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5),
    inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5);
}

.cross-rule {
  @include f-times-26;
  font-size: 24px;
  line-height: 100%;

  opacity: 100%;
  overflow: visible;
  color: black;
  border: none;
  margin: 0;
  padding-top: 28px;

  &[data-content]:before {
    content: attr(data-content);
  }
}

@media (min-width: 512px) {
  .modal-text {
    @include f-times-20;
  }
}
