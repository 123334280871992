/* Deja Vu Sans mono */
@font-face {
  font-family: "DejaVu Sans Mono";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../fonts/DejaVuSansMono.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../fonts/DejaVuSans.ttf") format("truetype");
}

/* ibm-plex-mono-regular - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/ibm-plex-mono-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/ibm-plex-mono-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/ibm-plex-mono-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../fonts/ibm-plex-mono-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../../fonts/ibm-plex-mono-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../fonts/ibm-plex-mono-v12-latin-regular.svg#IBMPlexMono")
      format("svg"); /* Legacy iOS */
}

/* ibm-plex-mono-bold - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/IBMPlexMono-Bold.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/IBMPlexMono-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/IBMPlexMono-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/IBMPlexMono-Bold.woff")
      format("woff"),
    /* Modern Browsers */ url("../../fonts/IBMPlexMono-Bold.ttf")
      format("truetype");
}

/* W95FA - latin */
@font-face {
  font-family: "W95FA";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/W95FA.otf") format("opentype");
  src: local(""), url("../../fonts/w95fa.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/w95fa.woff") format("woff");
}

/* DS-Digital - latin */
@font-face {
  font-family: "DS-Digital";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/ds_digital/DS-DIGI.TTF") format("truetype");
}

/* ChicagoFLF - latin */
@font-face {
  font-family: "ChicagoFLF";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/ChicagoFLF.ttf") format("truetype");
}
