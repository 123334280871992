@import "src/styles/mixins";

.mint-content-table {
  @include rmx-box;
  width: 100%;

  .content {
    @include f-times-18;
    height: 35px;
  }

  footer {
    @include f-caption;
    font-style: italic !important;
  }

  .small {
    width: 86px;
  }

  .medium {
    width: 110px;
  }

  &.dimmed {
    background: var(--box-dimmed-background);

    & > .content,
    & > .table div .content,
    .header .content {
      background: var(--box-dimmed-background);
    }
  }
}

@media (min-width: 512px) {
  .mint-content-table {
    .content {
      font-size: 20px !important;
      @include f-times-20;
    }

    .small {
      width: 96px;
    }

    .medium {
      width: 120px;
    }
  }
}
