.collection-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.grid-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.collection-grid {
  width: 920px;
  display: grid;
  grid-template-columns: 440px 440px;
  column-gap: 32px;
  row-gap: 32px;
}

.nft-card {
  border: 2px solid #gray;
  &:hover {
    background: #00f720;
  }
}

.nft-name {
  padding: 8px;
  text-align: center;
}

.nft-image {
  width: 420px;
  height: 420px;
}
