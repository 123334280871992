.howto-content {
  .howto-video {
    background: #c0c0c0;
    /* curve 2 active */
    box-shadow: inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5),
      inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5);
    margin-bottom: 8px;
  }

  .howto-description {
    padding: 10px;
    background: #ffffff;
    /* curve 2 active */
    box-shadow: inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5),
      inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5);
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-feature-settings: "calt" off, "liga" off;

    p {
      font-size: 16px;

      a {
        font-size: 16px;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 512px) {
  .howto-content {
    .howto-description {
      p {
        font-size: 18px;

        a {
          font-size: 18px;
        }
      }
    }
  }
}

// *** END HOWTO CONTENT
