@import "src/styles/mixins";

.mint-receipt {
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;

  background: linear-gradient(
    183.09deg,
    #05f700 14.67%,
    #b1ff00 44.95%,
    #bfff00 63.26%,
    #e3ff00 69.87%,
    #fffe00 80.91%,
    #ffcb00 92.87%,
    #f2cd23 103.77%,
    #ffcc00 104.38%,
    #ff928d 116.77%
  );

  .congrat {
    margin-top: 0;
    max-width: 335px;
  }

  .image-container {
    padding-top: 40px;
    padding-bottom: 40px;

    .dance {
      width: 284px;
      height: 200px;
    }
  }

  .info-container {
    max-width: 512px;
  }

  .remix-in-text {
    width: 100px;
  }

  .question-icon {
    box-shadow: inset 0.5px 0.5px 0px #e5e5e5, inset -0.5px -0.5px 0px #525252;
    margin-left: 6px;
    padding: 6px;
  }

  .hand {
    width: 26px;
  }

  .mint-content-card,
  .content-card {
    background: var(--rmx-palette-watermelon);

    .nft-image {
      background: white;
      max-width: 100%;
      transform: scale(1.15);
      margin-top: -44px;
      width: 343px;
    }
    .title-text {
      text-align: center;
    }
    .clock-container {
      padding: 0 !important;
    }

    .button-helper {
      border: none;
      height: 28px;
      width: 28px;
      .question-icon {
        margin-left: 0px;
        background: white;
        &:hover {
          background: var(--rmx-palette-watermelon);
        }
      }
    }

    & > .table {
      div {
        .content {
          &.trait-icon {
            height: 40px;
            width: 40px;
            padding: 0 !important;
            display: flex !important;
            align-items: center;
            align-content: center;
            justify-content: center;

            img {
              margin: 0;
              padding: 0;
              max-height: 30px;
              max-width: 30px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
.modal-info {
  padding: 8px;
}

@media (min-width: 512px) {
  .mint-receipt {
    .hand {
      width: 32px;
    }
    .content-card {
      .nft-image {
        width: 420px;
      }
    }
  }
}

@media (min-width: 512px) {
  .mint-receipt {
    .hand {
      width: 32px;
    }
    .content-card {
      .nft-image {
        width: 420px;
      }
    }
  }
}
