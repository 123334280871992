@import "src/styles/mixins";

.loader-container {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //z-index higher than 1030 set for CTA
  z-index: 1031;
  overflow: hidden;
}

.waiting-title {
  @include f-times-18;
  padding-top: 24px;
  margin: 0;
}

.logo-container {
  padding-top: 200px;
}

.image {
  width: 512px;
  height: 128px;
  object-fit: contain;
}

.no-scrolling {
  overflow: hidden;
}

@media (min-width: 512px) {
  .logo-container {
    padding-top: 400px;
  }

  .waiting-title {
    font-size: 20px;
    padding-top: 32px;
  }
}
