@import "src/styles/mixins";
@import "src/index";

.traits-container {
  padding-bottom: 6px;
  padding-top: 16px;

  * {
    @include f-dejavu-mono-base;
    font-size: 14px;
  }
  .traits-box {
    height: 46px;
    max-width: 1048px;
    padding: 6px;
    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
      inset -1px -1px 0px #e5e5e5;

    .counter {
      margin-top: 6.5px;
      width: 190px;
    }

    .green {
      background: $ooze;
    }
  }
}

@media (min-width: 512px) {
  .traits-container {
    padding-bottom: 12px;
    padding-top: 12px;

    * {
      @include f-dejavu-mono-base;
      font-size: 16px;
    }

    .traits-box {
      height: 50px;

      .counter {
        margin-top: 10px;
      }
    }
  }
}
