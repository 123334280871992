@import "src/styles/mixins";

.parts-nav {
  text-transform: uppercase;

  menu {
    margin: 0;
    padding: 0;
    height: 49px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: start;

    .label {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 2px;
      margin: 0;
      height: 100%;
      cursor: pointer;
    }
    .active > * {
      background: blue;
      color: white;
    }
  }

  hr,
  .hr {
    border-bottom: 1px solid var(--lighter-grey);
    margin-top: 0px;
    margin-bottom: 8px;
  }
}
