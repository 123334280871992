/* **************************************************************************
* RMX Party Global Default Styles
*
* Keep component-level styles component scoped where applicable
* use this file for defining styles for plain html tags and rules to be globally
* applied across all components.
*
* Keep Typography rules in the rmx-typography file
* Keep custom props and scss vars in the rmx-variables file
*
* */

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.spin {
  animation: spin 1s infinite steps(8);
}

.grayScale {
  backdrop-filter: grayscale(1);
  filter: grayscale(1);
}

.blur {
  backdrop-filter: blur(4px);
  filter: blur(4px);
}

.rmx-card {
  @include f-times-18;
  text-align: center;

  color: #000000;
}

.description {
  font-family: "W95FA";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* or 22px */
  letter-spacing: 0.01em;

  & a {
    color: blue;

    &:hover {
      color: indigo;
    }
  }
}
