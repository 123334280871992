@import "src/styles/mixins";

.wallet-connect-modalish {
  z-index: 1000;
  background: #f5f5f5;
  background-image: url("../../asset/gray-body.svg");
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #ffffff;
  opacity: 100%;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5), inset -1px -1px 0px #e5e5e5;
  background-size: contain;
  p {
    margin-bottom: 0;
  }

  .title-container {
    margin-bottom: 42px;
  }

  .text-line-2 {
    margin-top: 42px;
  }
  .cross-rule {
    @include f-times-26;
    font-size: 24px;
    line-height: 100%;

    opacity: 100%;
    overflow: visible;
    color: black;
    border: none;
    padding: 0;
    margin-top: 48px;

    &[data-content]:before {
      content: attr(data-content);
    }
  }
}

@media (min-width: 512px) {
  .wallet-connect-modalish {
    p {
      @include f-times-20;
    }
  }
}
