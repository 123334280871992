@import "src/styles/mixins";

.box-content-card {
  @include rmx-box;
  width: 100%;

  p {
    margin: 0;
    text-align: left;
  }

  .text-2 {
    padding-top: 12px;
  }
}

@media (min-width: 512px) {
  .mint-content-card {
    @include f-times-20;
  }
}
