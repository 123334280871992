@import "../../styles/mixins";

.clock-header {
  font-size: 18px;
}

.clock-content {
  @include f-dejavu-14;
  font-size: 14px;
  background-color: #00f720 !important;
}

@media (min-width: 512px) {
  .clock-header {
    font-size: 20px;
  }

  .clock-content {
    @include f-dejavu-16;
    font-family: "DejaVu Sans Mono", sans-serif;
    font-size: 16px !important;
  }
}
