@import "src/styles/mixins";
.nft-reveal {
  min-height: 100vh;
  padding: 1em;
  font-family: "Times New Roman";
  position: relative;

  > * {
    max-width: 512px;
  }

  .curse-detected {
    background-color: #000080;
    color: white;
    font-family: W95FA, serif;
    font-size: 1.3rem;
  }

  .minted-emoji-display {
    > * + * {
      margin-top: 0.5em;
    }

    .emoji-card {
      transform: rotateY(0deg);
      transition: 0.5s 0s transform linear;
      backface-visibility: visible;
      position: relative;
      width: 82%;
      margin-bottom: 32px;

      & > img {
        width: 100%;
        object-fit: contain;
        border: 0.649621px solid #e5e5e5;
        box-shadow: inset 1.62405px 1.62405px 0.5px rgba(229, 229, 229, 0.7);
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
        z-index: 999;
        transition: 0s 0.25s z-index linear;
        background-color: #ffffff;
      }

      .emoji-metadata {
        transform: rotateY(180deg);
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 0;
        transition: 0s 0.25s z-index linear;

        .back-title {
          padding-left: 8px;
          padding-bottom: 8px;

          .transaction-id {
            font-family: "DejaVu Sans", sans-serif;
            font-size: 13px;
            background-color: white;
            padding-left: 1px;
          }

          .nft-name-section {
            background-color: white;
            margin-left: 4px;
            height: 36px;

            .nft-name {
              margin-left: 4px;
              font-size: 30px;
              color: #0000ff;
              line-height: 25px;
            }
          }
        }

        .old-yeller {
          background-color: #ffef00;
        }

        .big-red {
          background-color: #ff0000;
        }

        .true-blue {
          background-color: #0000ff;
        }

        .yukon-gold {
          background-image: url("../../asset/potato-background.png");
        }
      }

      .genealogy {
        padding: 16px;

        .genealogy-title {
          font-family: "DejaVu Sans Mono", monospace;
          line-height: 22px;
          height: 22px;
          padding: 0 4px;
          background: linear-gradient(
            270deg,
            #ff00ef -18.56%,
            #ffef00 41.63%,
            #46f517 102.08%
          );
          font-size: 20px;
        }

        .content-back {
          padding: 0 8px;
        }

        .attribute-image {
          // height: 30px;
          // width: 30px;
          height: var(--nft-geneaology-icon-size);
          width: var(--nft-geneaology-icon-size);
        }

        .attribute-name {
          padding-left: 8px;
          white-space: nowrap;
          text-transform: capitalize;
        }

        .attribute-description {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .attribute-separator {
          height: 1px;
          background: linear-gradient(
            270deg,
            #ff00ef -18.56%,
            #ffef00 41.63%,
            #46f517 102.08%
          );
        }
      }

      &.flipped {
        transform: rotateY(180deg);

        & > img {
          z-index: 0;
        }

        .emoji-metadata {
          z-index: 999;
          font-weight: 400;
        }
      }
    }

    @media (max-width: 500px) {
      .emoji-card {
        width: 100%;
      }
    }

    .emoji-button {
      float: right;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        border: none;
        background: #ffffff;
        height: 50px;
        padding: 0px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4),
          inset 0.5px 0.5px 0px rgba(229, 229, 229, 0.5),
          inset -0.5px -0.5px 0px rgba(0, 0, 0, 0.5);

        img {
          padding: 8px;
          height: 100%;
        }
      }

      button[aria-pressed="true"] {
        box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
          inset -1px -1px 0px #e5e5e5;
      }

      label {
        text-transform: uppercase;
        margin-top: 8px;
        padding: 0.1em;
      }

      button[aria-pressed="true"] + label {
        text-transform: uppercase;
        background-color: #0000ff;
        color: white;
        padding: 0.1em;
      }
    }
  }

  .success-message {
    font-size: 20px;
    padding: 0.4em 0.2em;
    line-height: 120%;
    border-left: 3px solid black;
    border-top: 3px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }
}
