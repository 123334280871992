@import "src/styles/mixins";
.info-container {
  padding-bottom: 35px;
}
.explanation-img {
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 512px) {
  .info-container {
    padding-bottom: 43px;
  }
}
