@import "src/styles/mixins";

.error-container {
  max-width: 343px;
  margin: auto;
  padding-top: 27vh;

  .error-image {
    background: var(--rmx-palette-accent-one);
    padding-bottom: 34px;
    filter: invert(1);
  }

  .text-1,
  .text-2 {
    font-family: "W95FA", serif;
    background: #ffef00;
    text-align: center;
    margin-bottom: 0px;
    filter: invert(1);
  }

  .text-2 {
    margin-bottom: 32px;
  }
}

@media (min-width: 512px) {
  .error-container {
    max-width: 420px;
  }
  .text-2 {
    margin-bottom: 45px;
  }
}
