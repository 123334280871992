@import "src/styles/mixins";

.mint-content,
.mint-page {
  @include f-times-18;

  width: 100%;

  .green-button,
  .yellow-button,
  .red-button,
  .grey-button,
  .gray-button {
    min-width: 40px;
    min-height: 40px;
  }

  > * {
    margin: 0 auto;
    margin-top: var(--content-box-gap);
    max-width: 784px;
  }

  > * + * {
  }

  .mint-content-card,
  .content-card {
    @include rmx-box;
    width: 100%;

    footer {
      @include f-caption;
    }

    &.dimmed {
      background: var(--box-dimmed-background);
      & > .content,
      & > .table div .content,
      .header .content {
        background: var(--box-dimmed-background);
      }
    }

    p {
      margin: 0;
      text-align: left;
    }
    p + p {
      margin-top: 1em;
    }

    & > .content {
      width: 100%;
      display: block;
    }

    & > .table {
      display: flex;
      align-items: stretch;
      text-align: left;
    }
  }
}
.answer {
  font-style: italic;
  text-align: left;
}

@media (min-width: 512px) {
  .mint-content-card {
    @include f-times-20;
  }
}
