@import "fonts";

.table {
  margin-bottom: 0;
}

.btn {
  border-radius: 1px !important;
  border: 0 !important;
}

.btn-sm {
  @include f-times-14;
  text-decoration-line: underline;
}

.btn-link {
  user-select: none;
  cursor: default;
  color: blue;
  border: none;
  background: none;
  text-decoration: underline;

  &:hover {
    color: indigo;
  }
}

.btn-primary,
.btn-primary:focus {
  @include f-dejavu-16;
  text-decoration: none !important;

  width: fit-content;
  position: relative;
  border-radius: 1px !important;
  border: 0 !important;
  padding: 4px 24px;

  -moz-box-shadow: 6px 6px 2px rgba(0, 0, 0, 0.4),
    inset 0.5px 0.5px 0px rgba(230, 230, 230, 0.6),
    inset -1px -1px 0px rgba(0, 0, 0, 0.5);

  box-shadow: 6px 6px 2px rgba(0, 0, 0, 0.4),
    inset 0.5px 0.5px 0px rgba(230, 230, 230, 0.6),
    inset -1px -1px 0px rgba(0, 0, 0, 0.5);

  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &:hover {
    transform: translate3d(0.25em, 0.25em, 10em);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5),
      inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5),
      inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5);
  }
}

.btn-secondary,
.btn-secondary:focus {
  @include button-variant(
    $secondary,
    $secondary,
    $primary,
    $secondary,
    $secondary,
    $primary,
    $secondary,
    $secondary,
    $primary,
    $secondary,
    $secondary,
    $primary
  );

  width: fit-content;
  position: relative;
  border-radius: 1px !important;
  border: 0 !important;

  @include f-times-14;
  text-decoration-line: underline;

  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4),
    inset 0.25px 0.25px 0px rgba(255, 255, 255, 0.6),
    inset -0.5px -0.5px 0px rgba(0, 0, 0, 0.5);

  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5),
      inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5),
      inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5);

    -webkit-transform: translate3d(0.25em, 0.25em, 10em);
    transform: translate3d(0.25em, 0.25em, 10em);
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4),
      inset 0.25px 0.25px 0px rgba(255, 255, 255, 0.6),
      inset -0.5px -0.5px 0px rgba(0, 0, 0, 0.5);
  }
}

// testing green button variant
.green {
  @include button-variant(
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark
  );
}

.btn-green {
  @include button-variant(
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark,
    #00f720,
    #00f720,
    $dark
  );
  display: inline-block;
  //box-shadow: 6px 6px 2px rgba(0, 0, 0, 0.4),
  //inset 0.5px 0.5px 0px rgba(230, 230, 230, 0.6),
  //inset -1px -1px 0px rgba(0, 0, 0, 0.5);

  //box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4),
  //inset 0.25px 0.25px 0px rgba(255, 255, 255, 0.6),
  //inset -0.5px -0.5px 0px rgba(0, 0, 0, 0.5);

  box-shadow: inset 1px 1px 0px rgba(230, 230, 230, 0.8),
    inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5),
    inset -1.5px -1.5px 0px rgba(0, 0, 0, 0.8);

  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  outline: none !important;

  &:hover {
    //box-shadow: 0 0 0 rgba(0, 0, 0, 0.5),
    //  inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5),
    //  inset 0.5px 0.5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.8),
      inset -0.25px -0.25px 0px rgba(255, 255, 255, 0.5),
      inset -1.5px -1.5px 2px rgba(192, 192, 192, 0.8);

    -webkit-transform: translate3d(1px, 1px, 1em);
    transform: translate3d(1px, 1px, 1em);
  }

  &:active,
  &:focus {
    outline: none !important;
    border-radius: 1px;
    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.8),
      inset -1.5px -1.5px 2px rgba(192, 192, 192, 0.8);
  }

  &:disabled {
    border-radius: 1px;
    opacity: 0.5;
    box-shadow: inset 1px 1px 0 rgba(230, 230, 230, 0.8),
      inset -1.5px -1.5px 0px rgba(0, 0, 0, 0.8);
  }
}

// BEGIN CursedEmojis overrides; some of these may be candidates for whole-app overrides

// cursed emoji page primary button. react bootstrap variant: "ce-primary"
.btn-ce-primary,
.btn-ce-secondary {
  @include button-variant(
    #0000ff,
    /* background */ #0000ff,
    /* border */ $light,
    /* color */ #0000ff,
    /* hover-background */ #0000ff,
    /* hover-border */ #0000ff,
    /* hover-color */ #0000ff,
    /* active-background */ #0000ff,
    /* active-border */ #0000ff,
    /* active-color */ none,
    /* disabled-background */ #0000ff,
    /* disabled-border */ black /* disabled-color */
  );
  @include f-rmx-button;

  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;

  border: 2px solid #ffffff;
  box-shadow: inset 1px 1px 0px #e5e5e5, inset -1px -1px 0px #525252;

  &:hover,
  &:focus {
    background: var(--premint-watermelon);

    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
      inset -1px -1px 0px #e5e5e5;

    color: #0000ff !important;
  }

  &:active,
  &:active:focus {
    background: var(--premint-watermelon);
    outline: none !important;

    transform: translateY(1px);

    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
      inset -1px -1px 0px #e5e5e5;
    color: #0000ff !important;
  }

  &:disabled {
    background: none;
    color: #c0c0c0;
    box-shadow: none;
  }
}

.btn-ce-primary {
  height: var(--rmx-primary-cta-button-height);
  width: var(--rmx-primary-cta-button-width);
}
.btn-ce-secondary {
  height: var(--rmx-secondary-cta-button-height);
  width: var(--rmx-secondary-cta-button-width);
}

// END bootstrap overrides
