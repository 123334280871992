.overlay {
  background: white;
  backdrop-filter: blur(4px);
  opacity: 72%;
  position: absolute;
  z-index: 1020;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
