// NOTE: image URLS are built a bit strange

.remove-button-container {
  position: relative;
  padding-top: 19px;
  padding-bottom: 19px;
}

@media (min-width: 512px) {
  .remove-button-container {
    padding-top: 12px;
    padding-top: 8px;
  }
}
