@import "src/styles/mixins";
.builder-panel {
  margin-top: 4px;

  .header-builder {
    height: 60px;
    background: #f5f5f5;
    box-shadow: 1px 0.5px 0px rgba(0, 0, 0, 0.12),
      inset 0.5px 0.5px 1px rgba(0, 0, 0, 0.02), inset 0.5px 0.5px 0px #fffefa,
      inset -0.5px -0.5px 0px rgba(82, 82, 82, 0.12);

    .header-builder-name {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;

      .logo-image-name {
        width: 31px;
      }

      .logo-image {
        width: 100%;
      }

      .grey-lines {
        margin-left: 8px;
        height: 6px;
        width: 100%;
        border-bottom: solid 1px;
        border-top: solid 1px;
        border-image: linear-gradient(to right, #adabab, #cccccc) 1;
      }
    }

    .header-builder-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-top: 5px;
      padding-right: 8px;
      padding-left: 8px;

      .logo {
        height: 28px;
        width: auto;
      }
    }

    .button-helper {
      margin-left: 4px;
      border: none;
      height: 28px;
      width: 28px;
      justify-self: flex-end;
    }

    .link-icon-header {
      box-shadow: inset 0.5px 0.5px 0px #e5e5e5, inset -0.5px -0.5px 0px #525252;
      padding: 2px 6px;
      height: 28px;
      width: 28px;

      &:hover {
        background: var(--rmx-palette-watermelon);
      }
    }
  }
}

@media (min-width: 365px) {
  .builder-panel {
    .header-builder {
      .header-builder-content {
        .logo {
          height: 33px;
          width: auto;
        }
      }
    }
  }
}

@media (min-width: 512px) {
  .builder-panel {
    margin-top: 6px;

    .header-builder-content {
      gap: 32px;
    }

    .header-builder {
      height: 76px;

      .header-builder-name {
        .name {
          font-size: 16px;
        }
      }

      .button-helper {
        height: 40px;
        width: 40px;
      }

      .link-icon-header {
        padding: 6px 10px;
        height: 40px;
        width: 40px;
      }

      .header-builder-content {
        padding-top: 3px;

        .logo {
          height: 48px;
        }
      }
    }
  }
}
