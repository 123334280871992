/* **************************************************************************
* RMX Party Style Variables
*
* Use as a single source of truth for CSS values that would otherwise be
* duplicated and would need to be updated together based on design changes.
*
* Where possible, discuss design language with design team to help decide an
* appropriate role-based name for these values, so that the name describes the
* design purpose (reason for change) and not the value itself. e.g. use
* --call-to-action-color rather than --color-blue.
*
* Keep style rules in their respective stylesheet files, keep only variables
* here.
* */

:root {
  /* General theme colors */
  --primary-white: #ffffff;
  --primary-blue: #0000ff;
  --primary-black: #000000;
  --dark-grey: #525252;
  --light-grey: #c0c0c0;
  --lighter-grey: #e5e5e5;
  --lightest-grey: #f5f5f5;

  /* TODO: realign naming patterns for color palette vars e.g.
* --rmx-palette-black */
  --rmx-palette-black: #000000;
  --rmx-palette-white: #ffffff;
  --rmx-palette-primary: #0000ff;
  --rmx-palette-active: #00f720;
  --rmx-palette-accent-one: #ffef00;
  --rmx-palette-accent-two: #00f0ff;
  --rmx-palette-accent-three: #ff00ef;
  --rmx-palette-error-text: #ff0000;
  --rmx-palette-error-background: #0000aa;
  --rmx-palette-visited: #551a8b;
  --rmx-palette-dark-grey: #525252;
  --rmx-palette-light-grey: c0c0c0;
  --rmx-palette-lighter-grey: #e5e5e5;
  --rmx-palette-lightest-grey: #f5f5f5;
  --rmx-palette-watermelon: linear-gradient(
    270deg,
    #ff00ef -18.56%,
    #ffef00 41.63%,
    #46f517 102.08%
  );
  --rmx-palette-flamer: linear-gradient(
      265.22deg,
      rgba(153, 5, 5, 0) -1.35%,
      rgba(253, 57, 4, 0.413) 2.81%,
      rgba(252, 179, 1, 0.497) 10.61%,
      rgba(252, 252, 128, 0.7) 42.86%,
      rgba(252, 252, 128, 0.7) 49.62%,
      rgba(255, 255, 0, 0.7) 98.52%
    ),
    linear-gradient(
      266.88deg,
      rgba(255, 14, 5, 0.076) 2.87%,
      rgba(251, 210, 77, 0.2) 51.9%
    ),
    radial-gradient(49.75% 50% at 44.69% 57.69%, #ffef00 47.92%, #fca700 100%);

  --visited-link-color: #551a8b;
  --button-active-background: #00f720;
  --button-active-dimmed-background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    #00f720;
  --button-dimmed-gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.08),
    rgba(0, 0, 0, 0.08)
  );

  /* Special color gradients (named by appearance not by role) */
  --button-active-background-fire: linear-gradient(
      265.22deg,
      rgba(153, 5, 5, 0) -1.35%,
      rgba(253, 57, 4, 0.413) 2.81%,
      rgba(252, 179, 1, 0.497) 10.61%,
      rgba(252, 252, 128, 0.7) 42.86%,
      rgba(252, 252, 128, 0.7) 49.62%,
      rgba(255, 255, 0, 0.7) 98.52%
    ),
    linear-gradient(
      266.88deg,
      rgba(255, 14, 5, 0.076) 2.87%,
      rgba(251, 210, 77, 0.2) 51.9%
    ),
    radial-gradient(49.75% 50% at 44.69% 57.69%, #ffef00 47.92%, #fca700 100%);
  --premint-watermelon: linear-gradient(
    270deg,
    #ff00ef -18.56%,
    #ffef00 41.63%,
    #46f517 102.08%
  );

  /* RMX Box / Frame specs */
  --box-light-edge: #e5e5e5;
  --box-dark-edge: #bbbbbb;
  --rmx-box-gap: 3px;
  --box-dimmed-background: #f5f5f5;

  /* Typography constants/variables */
  --font-base-size: 18px;
  --font-copy-size: 18px;
  --font-caption-size: 16px;
  --font-link-size: 18px;
  --link-color: #0000ff;
  --font-button-size: 14px;
  --font-button-cta-size: 22px;
  --font-label-size: 14px;
  --font-nft-card-base-size: 18px;

  /* Layout spacing units */
  --content-box-gap: 32px;

  --nft-geneaology-icon-size: 1.2em;

  /* RMX Button Specs */
  --rmx-primary-cta-button-height: 48px;
  --rmx-primary-cta-button-width: 512px;
  --rmx-secondary-cta-button-height: 48px;
  --rmx-secondary-cta-button-width: 337px;
  --rmx-nav-button-height: 35px;
  --rmx-nav-button-width: 76px;
  --rmx-traitremove-button-height: 40px;
  --rmx-traitremove-button-width: 192px;
  --rmx-trait-button-height: 60px;
  --rmx-trait-button-width: 60px;
  --rmx-button-default-box-shadow: inset 0.5px 0.5px 0px #e5e5e5,
    inset -0.5px -0.5px 0px #525252;
  --rmx-button-pressed-box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.5),
    inset -1px -1px 0px #e5e5e5;
  --rmx-button-deeper-pressed-box-shadow: inset 1.5px 1.5px 0px
      rgba(0, 0, 0, 0.5),
    inset -1.5px -1.5px 0px #e5e5e5;
}

@media (min-width: 365px) {
  :root {
    --rmx-trait-button-height: 70px;
    --rmx-trait-button-width: 70px;
  }
}

/* For Cursed Emojis we are primarily concerned with this breakpoint for mobile
* vs desktop design adjustments.
* */
@media (min-width: 512px) {
  :root {
    /* Typography constants/variables */
    --font-copy-size: 20px;
    --font-caption-size: 18px;
    --font-link-size: 20px;
    --font-button-size: 16px;
    --font-button-cta-size: 26px;
    --font-label-size: 16px;
    --font-nft-card-base-size: 22px;

    /* Layout spacing units */
    --content-box-gap: 40px;

    --nft-geneaology-icon-size: 30px;

    /* RMX Button Specs */
    --rmx-primary-cta-button-height: 56px;
    --rmx-secondary-cta-button-height: 56px;
    --rmx-secondary-cta-button-width: 420px;
    --rmx-nav-button-height: 39px;
    --rmx-nav-button-width: 113px;
    --rmx-traitremove-button-height: 48px;
    --rmx-traitremove-button-width: 220px;
    --rmx-trait-button-height: 80px;
    --rmx-trait-button-width: 80px;
  }
}
