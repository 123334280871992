.cta-cursed-emoji {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);

  padding: 16px;
}
@media (min-width: 512px) {
  .cta-cursed-emoji {
    &.tall {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    &.short {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
