@import "../../styles/mixins";

.schedule {
  max-width: 512px !important;
  gap: 24px;
}

.intro {
  max-width: 784px !important;
}

.faq {
  max-width: 920px !important;
}

@media (min-width: 512px) {
  .schedule {
    gap: 32px;
  }

  .separator {
    padding: 1em 0 !important;
  }

  .content {
    font-size: 20px;
  }
}
