@import "src/styles/mixins";

.cursed-emojis-page {
  width: 100%;
  padding-top: 45px;

  .cursed-emojis-header a {
    @include f-times-26;
  }

  .genesis-subnav {
    width: 100%;
    margin-top: 0;
    margin-bottom: var(--content-box-gap);

    & > * {
      margin: 0 auto;
      max-width: 512px;
    }
  }

  .genesis-subnav-divider {
    @include f-times-26;
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
  }

  & .connected-message {
    @include f-state-confirmation;

    background: linear-gradient(
      90deg,
      #00f0ff 0%,
      #ffef00 50.76%,
      #ff8a00 100%
    );
    padding: 4px 10px;
    margin: 0;
  }
}
