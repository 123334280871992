@import "src/styles/mixins";

.schedule-event-window {
  @include rmx-box;

  .header,
  header {
    @include f-times-18;
    text-transform: none !important;
  }
  footer {
    font-size: 16px;
  }

  &.past {
    background-color: var(--box-dimmed-background);
    .content {
      border-top-color: rgba(0, 0, 0, 0.4) !important;
      border-right-color: rgba(255, 255, 255, 1) !important;
      border-bottom-color: rgba(255, 255, 255, 1) !important;
      border-left-color: rgba(0, 0, 0, 0.4) !important;
      background-color: var(--box-dimmed-background);
    }
    .table .content {
      background-color: var(--box-dimmed-background) !important;
    }

    .header,
    header {
      text-decoration: none !important;
      color: red;
    }

    div,
    footer {
      text-decoration: line-through;
    }
  }

  .inner-table {
    font-size: 16px;
  }

  &.current {
    .header,
    header {
      text-decoration: underline;
      color: blue;
    }
  }
}

@media (min-width: 512px) {
  .schedule-event-window {
    .header,
    header {
      @include f-times-20;
      font-size: 20px !important;
    }
    footer {
      font-size: 18px !important;
    }
    .inner-table {
      font-size: 20px;
    }
  }
}
