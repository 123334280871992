.parts-display {
  padding: 16px 0;

  .selector-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .parts-display-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(60px, 1fr));
    gap: 16px;

    .yellow-highlight {
      box-shadow: -1px -1px 3px rgba(255, 239, 0, 0.75),
        1px 1px 3px rgba(255, 239, 0, 0.75), inset 2.5px 2.5px 1px #e5e5e5,
        inset -2px -2px 1px rgba(0, 0, 0, 0.5);
      border: 1px solid #ffef00;
    }

    .blue-highlight {
      box-shadow: -1px -1px 3px rgba(0, 0, 255, 0.75),
        1px 1px 3px rgba(0, 0, 255, 0.75), inset 2.5px 2.5px 1px #e5e5e5,
        inset -2px -2px 1px rgba(0, 0, 0, 0.5);
      border: 1px solid #0000ff;
    }

    .red-highlight {
      box-shadow: -1px -1px 3px rgba(255, 0, 0, 0.75),
        1px 1px 3px rgba(255, 0, 0, 0.75), inset 2.5px 2.5px 1px #e5e5e5,
        inset -2px -2px 1px rgba(0, 0, 0, 0.5);
      border: 1px solid #ff0000;
    }

    .potato-highlight {
      box-shadow: -1px -1px 3px rgba(211, 94, 39, 0.75),
        1px 1px 3px rgba(211, 94, 39, 0.75), inset 2.5px 2.5px 1px #e5e5e5,
        inset -2px -2px 1px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(211, 94, 39, 1);
    }

    & > div.item {
      background: white;
      padding: 1rem;
      position: relative;

      border-radius: 1px;
      box-shadow: inset 2.5px 2.5px 1px #e5e5e5,
        inset -2px -2px 1px rgba(0, 0, 0, 0.5);
      border: 1px solid #ffffff;

      &.selected {
        /* lighter grey */
        background: #e5e5e5;
        /* White */
        border: 0.5px solid #ffffff;
        box-shadow: inset 2.5px 2.5px 1px rgba(0, 0, 0, 0.5),
          inset -2px -2px 1px rgba(255, 255, 255, 0.7);
        border-radius: 1px;
      }

      &::before {
        content: "";
        padding-bottom: 100%;
        display: block;
      }

      & .feature-image-holder {
        position: absolute;
        width: 90%;
        height: 90%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background: center center no-repeat;
        background-size: contain;
      }
    }
  }
}
@media (min-width: 512px) {
  .parts-display {
    .parts-display-grid {
      // grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
