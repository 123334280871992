@import "src/styles/mixins";

.my-modal {
  .modal-content {
    background: #f5f5f5;
    border: 0.649621px solid #ffffff;
    border-radius: 0;
    padding: 0 12px 12px 12px;

    .modal-header {
      border: 0;
      padding: 6px 8px;
      min-height: 48px;
      //font-family: "IBM Plex Mono", sans-serif;
      @include f-times-18;
      text-align: center;
      display: flex;
      justify-content: center;

      :first-child {
        flex-grow: 1;
        text-transform: uppercase;
        text-align: center;
        padding-left: 16px;
      }

      .btn-close {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        padding: 0;
        opacity: 100;

        background: white center center no-repeat
          url("../images/modal-close.svg");
        border-radius: 0;
        /* btn-border-secondary */

        box-shadow: -0.5px -0.5px 2px rgba(0, 0, 0, 0.1),
          0px -2px 22px rgba(0, 0, 0, 0.06), inset 0.5px 0.5px 0px #ffffff,
          inset -0.5px -0.5px 0px rgba(82, 82, 82, 0.2);
      }
    }

    .modal-body {
      background: #ffffff;

      padding: 0;

      font-family: "IBM Plex Mono", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 110%;
      letter-spacing: 0.005em;
    }

    .modal-primary-btn {
      max-width: 338px;
      margin-top: 12px;
    }
  }
}

@media (min-width: 512px) {
  .my-modal {
    .modal-content {
      .modal-header {
        @include f-times-20;
      }
    }
  }
}
