@import "src/styles/mixins";

.header-container {
  margin-bottom: 6px;
  max-width: 512px;
  background-color: white;
}

.header-image {
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  max-height: 100%;
}
.header-text {
  @include f-times-18;
  text-align: center;
  margin-top: -6px;
  margin-bottom: 0;
  padding-bottom: 8px;
}

@media (min-width: 512px) {
  .header-text {
    margin-top: -8px;
    @include f-times-20;
  }

  .header-image {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
