@import "src/styles/mixins";

hr.cursed-hr {
  @include f-times-26;
  font-size: 24px;
  line-height: 100%;

  margin: 0;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding: 0;

  opacity: 100%;
  overflow: visible;
  color: black;
  border: none;

  &:before {
    text-align: center;
    width: 100%;
    display: block;
  }

  &[data-content]:before {
    content: attr(data-content);
  }

  /* Some particular variants have special treatment */
  &.down-arrow {
    margin-top: 1em !important;
    margin-bottom: 4em !important;

    &:before {
      content: "↓";
    }
  }
}

@media (min-width: 512px) {
  hr.cursed-hr {
    margin: 0;
    margin-top: 2em !important;
    margin-bottom: 2em !important;
    padding: 0;
  }
}
