@import "src/styles/mixins";

.remix-content {
  video {
    margin: 0 auto;
    padding: 0 !important;
    max-width: min(420px, 100%);
    max-height: 100%;
    object-fit: contain;
  }

  .table-section {
    max-width: 512px;
  }

  .sub-content {
    height: 68px !important;
  }

  .timer {
    margin: 0;
    padding: 2px;
  }

  .faq-link {
    padding-left: 4px;
  }
}
