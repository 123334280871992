@import "../../../styles/mixins";

.cursed-emojis-footer {
  @include f-times-18;
  margin-top: 168px !important;
  padding-top: 16px;
  padding-bottom: 150px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: white;
  max-width: 100% !important;

  .image-icon {
    margin: 4px;
    height: 32px;
  }

  .logo-section {
    padding-top: 28px;
  }

  .logo-text {
    margin-top: -4px;
    font-size: 16px;
  }

  .copyright {
    padding-top: 16px;
  }

  @media (min-width: 512px) {
    .copyright {
      padding-top: 24px;
    }
    .logo-section {
      padding-top: 54px;
    }
  }
}
