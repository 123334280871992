/* mixins */

/* typography mixins */

// There are some special cases that fall outside the style guide and are not
// supposed to be re-used across components which aren't represented here.
// Hardcoded font overrides in individual component sytlesheets etc should be
// treated as special one-off cases.

@mixin f-times-base {
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0em;
}

@mixin f-times-14 {
  @include f-times-base;
  font-size: 14px;
}
@mixin f-times-12 {
  @include f-times-base;
  font-size: 12px;
  line-height: 110%;
}

@mixin f-times-16 {
  @include f-times-base;
  font-size: 16px;
}

@mixin f-times-18 {
  @include f-times-base;
  font-size: 18px;
}

@mixin f-times-20 {
  @include f-times-base;
  font-size: 20px;
}

@mixin f-times-26 {
  @include f-times-base;
  font-size: 26px;
  letter-spacing: 0.02em;
}

@mixin f-dejavu-mono-base {
  font-family: "DejaVu Sans Mono", monospace;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 100%;
  white-space: pre-wrap;
}

@mixin f-dejavu-10 {
  @include f-dejavu-mono-base;
  font-size: 10px;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin f-dejavu-12 {
  @include f-dejavu-mono-base;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin f-dejavu-14 {
  @include f-dejavu-mono-base;
  font-size: 14px;
  line-height: 90%;
}

@mixin f-dejavu-16 {
  @include f-dejavu-mono-base;
  font-size: 16px;
  line-height: 90%;
}

@mixin f-rmx-button {
  @include f-dejavu-mono-base;
  font-weight: 400;
  font-size: var(--font-button-size);
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 0.02em;
}

@mixin f-caption {
  @include f-times-base;
  font-style: italic;
  font-size: var(--font-caption-size);
}

@mixin f-digital-clock {
  font-family: "DS-Digital";

  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 90%;

  letter-spacing: 0.01em;
  white-space: pre-wrap;
}

// Used in remix loading states
@mixin f-state-confirmation {
  font-family: "W95FA";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
}

@mixin tool-label {
  @include f-dejavu-mono-base;
  font-size: 11px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
}

/* layout structure mixins */

@mixin rmx-box {
  padding: var(--rmx-box-gap);
  border-style: solid;
  border-width: 1px;
  border-top-color: var(--box-light-edge);
  border-right-color: var(--box-dark-edge);
  border-bottom-color: var(--box-dark-edge);
  border-left-color: var(--box-light-edge);

  & > header,
  & > .header {
    margin-top: 0;

    &.lighted {
      display: flex !important;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
    }

    & > * + * {
      margin-left: var(--rmx-box-gap);
    }
  }

  > * {
    margin: 0;
    flex-grow: 1;
  }

  .content {
    background: white;
    display: block;
    flex-grow: 1;
    border-style: solid;
    border-width: 1px;
    border-top-color: var(--box-dark-edge);
    border-right-color: var(--box-light-edge);
    border-bottom-color: var(--box-light-edge);
    border-left-color: var(--box-dark-edge);
    padding: 4px 6px;

    .link-icon {
      box-shadow: inset 0.5px 0.5px 0px #e5e5e5, inset -0.5px -0.5px 0px #525252;
      margin-left: 6px;
      padding: 4px;
      height: 1.2em;
      width: 1.2em;
      vertical-align: text-bottom;
    }

    &.copy {
      padding: 10px;
    }
  }

  > * + *,
  //TODO this seems to fix some issues where tables don't align correctly
  //& > .table > * + *,
  & > .table div > * + * {
    margin-top: var(--rmx-box-gap);
  }

  & > .table > * + *,
  .header > * + * {
    margin-left: var(--rmx-box-gap);
  }
}
