@import "./mixins.scss";
/*
* Global Typography rules for RMX Party / Cursed Emojis
*
* see Figma style guide
* https://www.figma.com/file/axw7cTdTVDJwuqDiZFu35R/Product-%E2%80%93-Q4-2022?node-id=3239%3A9712&t=SvikmEc5IC9fqiky-1
*
* keep non-font rules in the corresponding place in the other files
* */

html,
body,
p {
  @include f-times-base;
  font-size: var(--font-copy-size);
}

a,
.Link,
.link {
  @include f-times-base;
  font-size: var(--font-link-size);
  color: var(--link-color);
  text-decoration-thickness: 0.075em;

  &:visited,
  &.visited {
    color: var(--visited-link-color);
  }
}

caption,
.caption {
  @include f-caption;
}

button,
.rmx-button {
  @include f-rmx-button;
}

.label,
label {
  @include f-dejavu-mono-base;
  font-size: var(--font-label-size);
}

.timer {
  @include f-digital-clock;
}

.copyright {
  @include f-dejavu-12;
}

.tagline {
  @include f-times-16;
}

.rmx-box {
  .header,
  header {
    .content {
      @include f-times-18;
    }
  }
}

.content {
  &.copy {
    font-size: var(--font-copy-size);
    line-height: 120%;
  }

  .footer,
  footer {
    @include f-caption;
  }
}
